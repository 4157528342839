
  import React from 'react'
const Maintenance = () => {
    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto text-center">
                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative" role="alert">
                    <h4 className="text-lg font-semibold">We are under maintenance</h4>
                    <p className="mt-2 text-sm">We’ll be back shortly. Thank you for your patience!</p>
                </div>
                <a href='/' className="mt-4 inline-block text-blue-500 hover:underline">Back to Home</a>
            </div>
        </div>
    );
}

export default Maintenance
